/* Banner */

#banner {
	@include vendor('align-items', 'center');
	@include vendor('display', 'flex');
	@include padding(4em, 0, (3em, 0, 0, 0));
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-bottom: 0 !important;
	cursor: default;
	margin-bottom: -4em;
	max-height: 32em;
	min-height: 60vh;
	position: relative;
	top: -4em;

	&:after {
		@include vendor('transition', 'opacity #{_duration(banner)} ease');
		@include vendor('transition-delay', '0.75s');
		@include vendor('pointer-events', 'none');
		background-color: _palette(bg);
		content: '';
		position: absolute;
		display: block;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		opacity: 0.95;
		z-index: 1;
	}
	&.home:after, &.style0:after {
		opacity: 0.9;
	}

	h1 {
		font-size: 3.5em;
		font-weight: 700;
	    text-transform: uppercase;
	}

	> .inner {
		@include vendor('transition', (
			'opacity 1.5s ease',
			'transform 0.5s ease-out',
			'filter 0.5s ease',
			'-webkit-filter 0.5s ease'
		));
		padding: 0 !important;
		position: relative;
		z-index: 2;

		.image {
			display: none;
		}

		header {
			width: auto;

			> :first-child {
				width: auto;

				&:after {
					max-width: 100%;
				}
			}
		}

		.content {
			@include vendor('display', 'flex');
			@include vendor('align-items', 'center');
			margin: 0 0 _size(element-margin) 0;

			> * {
				margin-bottom: _size(element-margin);
			}

			p {
				margin-right: 2.5em;
				max-width: 800px;
				font-size: 1em;
				font-weight: _font(weight-bold);
			}
		}
	}

	&.home {
		height: 75vh;
		min-height: 30em;
		max-height: 50em;
		@include padding(3em, 0, (2em, 0, 0, 0));

		h1 {
			font-size: 4em;
		}

		&.alt {
			  opacity: 0.75;
		}
	}

	&.style0 { &:after { background-color: _palette(bg) } }
	&.style1 { &:after { background-color: _palette(accent1); } }
	&.style2 { &:after { background-color: _palette(accent2); } }
	&.style3 { &:after { background-color: _palette(accent3); } }
	&.style4 { &:after { background-color: _palette(accent4); } }
	&.style5 { &:after { background-color: _palette(accent5); } }
	&.style6 { &:after { background-color: _palette(accent6); } }

	body.is-loading & {
		&:after {
			opacity: 1.0;
		}

		> .inner {
			@include vendor('filter', 'blur(0.125em)');
			@include vendor('transform', 'translateX(-0.5em)');
			opacity: 0;
		}
	}

	@include breakpoint(large) {
		background-attachment: scroll;
	}

	@include breakpoint(medium) {
		> .inner {
			.content {
				display: block;

				> p {
					margin-right: 0;
				}
			}
		}
	}

	@include breakpoint(small) {
		@include padding(5em, 0, (3em, 0, 0, 0));
		height: auto;
		margin-bottom: -2.75em;
		max-height: none;
		min-height: 0;
		top: -2.75em;

		h1 {
			font-size: 2.5em;
		}

		> .inner {
			.content {
				p {
					br {
						display: none;
					}
				}
			}
		}

		&.home {
			height: auto;
			min-height: 0;
			max-height: none;

			@include padding(5em, 0, (2em, 0, 0, 0));

			h1 {
				font-size:3em;
			}
		}
	}

	@include breakpoint(xsmall) {

		&.home {
			h1 {
				font-size: 2.75em;
			}
		}
	}

	@include breakpoint(xxsmall) {

		h1 {
			font-size: 2.25em;
		}

		&.home {
			h1 {
				font-size: 2.5em;
			}
		}
	}
}

$aspect21: '(min-aspect-ratio: 2/1)';
@media #{$aspect21} {

	#banner {
		&.home {
			@include padding(4em, 0, (2em, 0, 0, 0));
		}


		@include breakpoint(medium) {

			&.home {
				h1 {
					font-size: 3em;
				}
			}
		}
	}
}
